<template>
  <vs-card>
    <div slot="header">
      <feather-icon
        icon="ChevronLeftIcon"
        svgClasses="h-4 w-4"
        class="mr-2 cursor-pointer"
        @click="$router.push({ name: 'app-user' })"
      ></feather-icon>
      <vs-label style="font-weight: bold">App User List</vs-label>
    </div>
    <h4 class="mb-2 ml-5 mt-2">Please fill the user details</h4>
    <vs-form autocomplete="off">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>User Type</label>
            <vs-select
              vs-multiple
              autocomplete
              placeholder="Select your user type"
              name="userType"
              v-validate="'required'"
              v-model="userData.userType"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in userOptions"
              />
            </vs-select>
            <span class="text-danger text-xxsm">
              {{ errors.first("userType") }}
            </span>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="!errors.first('firstName') && userData.firstName != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('firstName') ? true : false"
              :danger-text="errors.first('firstName')"
              v-validate="'required|max:150'"
              name="firstName"
              data-vv-as="first name"
              label="First Name"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              class="w-full"
              v-model="userData.firstName"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="!errors.first('lastName') && userData.lastName != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('lastName') ? true : false"
              v-validate="'required|max:150'"
              name="lastName"
              data-vv-as="last name"
              label="Last Name"
              class="w-full"
              :danger-text="errors.first('lastName')"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              v-model="userData.lastName"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('preferredName') && userData.preferredName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('preferredName') ? true : false"
              v-validate="'max:150'"
              name="preferredName"
              data-vv-as="preferred name"
              label="Preferred Name(Optional)"
              class="w-full"
              :danger-text="errors.first('preferredName')"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              v-model="userData.preferredName"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="!errors.first('email') && userData.email != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('email') ? true : false"
              v-validate="'required|email'"
              name="email"
              data-vv-as="email"
              label="Email"
              class="w-full"
              :danger-text="errors.first('email')"
              icon-no-border
              icon="icon icon-mail"
              icon-pack="feather"
              v-model="userData.email"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('contactNumber') && userData.contactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('contactNumber') ? true : false"
              v-validate="'required'"
              name="contactNumber"
              :danger-text="errors.first('contactNumber')"
              data-vv-as="contact number"
              label="Contact Number"
              class="w-full"
              icon-no-border
              icon="icon icon-phone"
              icon-pack="feather"
              v-model="userData.contactNumber"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              type="password"
              :success="!errors.first('password') && userData.password != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('password') ? true : false"
              v-validate="{
                required: true,
                min: 8,
                regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
              }"
              name="password"
              data-vv-as="password"
              label="password"
              class="w-full"
              :danger-text="errors.first('password')"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              v-model="userData.password"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('ahpraRegistrationNumber') &&
                userData.ahpraRegistrationNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('ahpraRegistrationNumber') ? true : false"
              v-validate="'required'"
              name="ahpraRegistrationNumber"
              :danger-text="errors.first('ahpraRegistrationNumber')"
              data-vv-as="AHPRA Registration Number"
              label="AHPRA Registration Number"
              class="w-full"
              icon-no-border
              icon="icon icon-briefcase"
              icon-pack="feather"
              v-model="userData.ahpraRegistrationNumber"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>AHPRA Expiry date</label>
            <datepicker
              format="dd/MM/yyyy"
              :disabled-dates="disabledDates"
              v-model="userData.ahpraExiryDate"
              :input-class="{
                'is-true':
                  !errors.has('ahpraExiryDate') && userData.ahpraExiryDate,
                'is-danger': errors.has('ahpraExiryDate'),
              }"
            >
              <template slot="afterDateInput">
                <span
                  v-if="userData.ahpraExiryDate"
                  class="
                    bg-color-done
                    input-icon-validate
                    vs-input--icon-validate
                  "
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('ahpraExiryDate')"
                  class="
                    bg-color-error
                    input-icon-validate
                    vs-input--icon-validate
                  "
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </datepicker>
            <span class="text-danger" style="font-size: 0.75em">
              {{ errors.first("ahpraExiryDate") }}
            </span>
            <input
              type="hidden"
              data-vv-as="APHRA expiry date"
              data-vv-validate-on="change"
              name="ahpraExiryDate"
              v-validate="'required'"
              v-model="userData.ahpraExiryDate"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('indemnityInsuranceProvider') &&
                userData.indemnityInsuranceProvider != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="
                errors.first('indemnityInsuranceProvider') ? true : false
              "
              v-validate="'required'"
              :danger-text="errors.first('indemnityInsuranceProvider')"
              name="indemnityInsuranceProvider"
              data-vv-as="indemnity insurance provider"
              label="Indemnity Insurance Provider"
              class="w-full"
              icon-no-border
              icon="icon icon-file"
              icon-pack="feather"
              v-model="userData.indemnityInsuranceProvider"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('indemnityInsuranceNumber') &&
                userData.indemnityInsuranceNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('indemnityInsuranceNumber') ? true : false"
              v-validate="'required'"
              name="indemnityInsuranceNumber"
              :danger-text="errors.first('indemnityInsuranceNumber')"
              data-vv-as="indemnity insurance number"
              label="Indemnity Insurance number"
              class="w-full"
              icon-no-border
              icon="icon icon-file"
              icon-pack="feather"
              v-model="userData.indemnityInsuranceNumber"
            />
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>Indemnity Insurance Expiry</label>
            <datepicker
              format="dd/MM/yyyy"
              :disabled-dates="disabledDates"
              v-model="userData.indemnityInsuranceExpiry"
              :input-class="{
                'is-true':
                  !errors.first('indemnityInsuranceExpiry') &&
                  userData.indemnityInsuranceExpiry,
                'is-danger': errors.first('indemnityInsuranceExpiry'),
              }"
            >
              <template slot="afterDateInput">
                <span
                  v-if="userData.indemnityInsuranceExpiry"
                  class="
                    bg-color-done
                    input-icon-validate
                    vs-input--icon-validate
                  "
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('indemnityInsuranceExpiry')"
                  class="
                    bg-color-error
                    input-icon-validate
                    vs-input--icon-validate
                  "
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </datepicker>
            <span class="text-danger text-xxsm">
              {{ errors.first("indemnityInsuranceExpiry") }}
            </span>
            <input
              type="hidden"
              data-vv-as="Indemnity Insurance Expiry"
              data-vv-validate-on="change"
              name="indemnityInsuranceExpiry"
              v-validate="'required'"
              v-model="userData.indemnityInsuranceExpiry"
            />
          </div>
        </vs-col>

        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          vs-w="6"
          v-if="this.userData.userType === 'doctor'"
        >
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('prescriberNumber') &&
                userData.prescriberNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('prescriberNumber') ? true : false"
              v-validate="'required|digits:7'"
              name="prescriberNumber"
              :danger-text="errors.first('prescriberNumber')"
              data-vv-as="prescriber number"
              label="Prescriber Number"
              class="w-full"
              icon-no-border
              icon="icon icon-briefcase"
              icon-pack="feather"
              v-model="userData.prescriberNumber"
            />
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>Upload Profile Picture</label>
            <div
              v-on:change="change('profilePictureUpload')"
              id="profilePictureUpload"
            >
              <vs-upload
                automatic
                name="profileImage"
                action="/api/v1/doctors/upload"
                text="Attach Document"
                accept=".png, .jpg, .jpeg"
                limit="1"
                v-validate="'required'"
                @on-error="handleError('profilePictureUpload')"
                @on-delete="deleteFile('p')"
                @on-success="successUpload('p', $event)"
              />
              <span class="text-danger text-xxsm">
                {{ errors.first("profileImage") }}
              </span>
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>Attach AHPRA Certificate</label>
            <div v-on:change="change('aphraUpload')" id="aphraUpload">
              <vs-upload
                automatic
                name="ahpraCertificate"
                action="/api/v1/doctors/upload"
                text="Attach Document"
                accept=".png, .jpg, .jpeg, .pdf"
                limit="1"
                v-validate="'required'"
                @on-error="handleError('aphraUpload')"
                @on-delete="deleteFile('a')"
                @on-success="successUpload('a', $event)"
              />
              <span class="text-danger text-xxsm">
                {{ errors.first("ahpraCertificate") }}
              </span>
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>Attach Insurance Certificate</label>
            <div v-on:change="change('insuranceUpload')" id="insuranceUpload">
              <vs-upload
                automatic
                name="insuranceCertificate"
                action="/api/v1/doctors/upload"
                text="Attach Document"
                accept=".png, .jpg, .jpeg, .pdf"
                limit="1"
                v-validate="'required'"
                @on-error="handleError('insuranceUpload')"
                @on-delete="deleteFile('i')"
                @on-success="successUpload('i', $event)"
              />
              <span class="text-danger text-xxsm">
                {{ errors.first("insuranceCertificate") }}
              </span>
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label>Attach Electronic Signature</label>
            <div v-on:change="change('signatureUpload')" id="signatureUpload">
              <vs-upload
                automatic
                name="electronicSignature"
                action="/api/v1/doctors/upload"
                text="Attach Document"
                accept=".png, .jpg, .jpeg, .pdf"
                limit="1"
                v-validate="'required'"
                @on-error="handleError('signatureUpload')"
                @on-delete="deleteFile('s')"
                @on-success="successUpload('s', $event)"
              />
              <span class="text-danger text-xxsm">
                {{ errors.first("electronicSignature") }}
              </span>
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label class="label">Account Status</label>
            <vs-switch
              v-model="statusSwitch"
              @click="editStatus()"
              class="attachment"
            ></vs-switch>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label class="label">Admin Approval</label>
            <vs-switch
              v-model="adminApprovalSwitch"
              @click="editApproval()"
              class="attachment"
            ></vs-switch>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <vs-button :disabled="!validateForm" @click="saveUserDetails"
              >Create {{ this.userData.userType | capitalize }}</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-form>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

const dict = {
  custom: {
    userType: {
      required: "Please select your user type",
    },
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    prescriberNumber: {
      required: "Please enter your prescriber number with seven digits",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    ahpraCertificate: {
      required: "Please upload your AHPRA certificate file",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    insuranceCertificate: {
      required: "Please upload your insurance certificate file",
    },
    electronicSignature: {
      required: "Please upload your signature file",
    },
    profileImage: {
      required: "Please upload your profile image",
    },
    password: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "createAppUser",
  components: {
    Datepicker,
  },
  data() {
    return {
      userData: {
        userType: "",
        firstName: "",
        lastName: "",
        preferredName: "",
        contactNumber: "",
        email: "",
        password: "",
        prescriberNumber: "",
        ahpraRegistrationNumber: "",
        ahpraExiryDate: "",
        indemnityInsuranceProvider: "",
        indemnityInsuranceNumber: "",
        indemnityInsuranceExpiry: "",
        ahpraCertificate: "",
        insuranceCertificate: "",
        signature: "",
        status: "",
        hasApprovedByAdmin: "",
      },
      statusSwitch: false,
      adminApprovalSwitch: false,
      disabledDates: { to: new Date() },
      userOptions: [
        { text: "Select User Type", value: "" },
        { text: "Doctor", value: "doctor" },
        { text: "Nurse", value: "nurse" },
      ],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions("appUser", ["saveAppUser"]),
    async saveUserDetails() {
      let self = this;
      let valid = await this.$validator.validate();
      if (this.userData.ahpraCertificate == "") {
        valid = false;
        self.errors.add({
          field: "aphraCertificate",
          msg: "APHRA Certificate is required",
        });
      } else {
        self.errors.remove("aphraCertificate");
      }
      if (this.userData.insuranceCertificate == "") {
        valid = false;
        self.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
        });
      } else {
        self.errors.remove("insuranceCertificate");
      }
      if (this.userData.signature == "") {
        valid = false;
        self.errors.add({
          field: "electronicSignature",
          msg: "Electronic Signature is required",
        });
      } else {
        self.errors.remove("electronicSignature");
      }
      if (!valid) {
        return false;
      }
      this.$store
        .dispatch("clinic/checkEmail", this.userData.email)
        .then((res) => {})
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors.add({
              field: "email",
              msg: err.response.data.message,
            });
          } else {
            this.$vs.notify({
              title: "Register Attempt",
              text: "Server Error",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
        });
      let info = this.userData;
      this.saveAppUser({ info }).then((res) => {
        this.$vs.notify({
          subject: "Save Page Content",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({ name: "app-user" });
      });
    },
    editStatus() {
      if (this.statusSwitch === true) {
        this.userData.status = "Disabled";
      }
      if (this.statusSwitch === false) {
        this.userData.status = "Active";
      }
    },
    editApproval() {
      if (this.adminApprovalSwitch === true) {
        this.userData.hasApprovedByAdmin = "false";
      }
      if (this.adminApprovalSwitch === false) {
        this.userData.hasApprovedByAdmin = "true";
      }
    },
    change(key = "") {
      this.$vs.loading({
        text: "Uploading Image...",
        color: "blue",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.userData.ahpraCertificate = response.Location;
        this.errors.remove("aphraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.userData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }
      if (tab == "s") {
        this.$vs.loading.close("#signatureUpload div div > .con-vs-loading");
        this.userData.signature = response.Location;
        this.errors.remove("electronicSignature");
      }
      if (tab == "p") {
        this.$vs.loading.close(
          "#profilePictureUpload div div > .con-vs-loading"
        );
        this.userData.profileImage = response.Location;
        this.errors.remove("profileImage");
      }
      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.userData.ahpraCertificate = "";
      if (tab == "i") this.userData.insuranceCertificate = "";
      if (tab == "s") this.userData.signature = "";
      if (tab == "p") this.userData.profileImage = "";
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>
<style>
ul {
  list-style: none;
  margin-left: 0px;
}
.attachment {
  margin-left: 10px;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}
</style>
